import { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import cn from 'classnames'
import * as yup from 'yup'

import { getSwapParams } from '@/api/bridge'
import { TGetSwapInfoResponse } from '@/api/bridge/types'
import { TBalancesItem } from '@/api/indexer/types'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { Button, Icon, Typography } from '@/libs/common'
import { chainsConfig } from '@/libs/configs/chains.config'
import { AppRoute, IconName } from '@/libs/enums'
import { debounce, formatNumber, setDecimals } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { TChainConfig } from '@/libs/types/chain.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { TBridgeConfirmState } from '@/pages/modal-page/libs/components/bridge-confirm/libs/types'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { BridgeAmountInput } from './libs/components/bridge-amount-input'
import { BridgeTokenSelectWithWallets } from './libs/components/bridge-token-select/libs/components/bridge-token-select-with-wallets'
import { TBridgeTokenItem } from './libs/components/bridge-token-select/libs/types'
import { EBridgeInputMode } from './libs/enums'
import styles from './styles.module.scss'

const calculateReceiveDebounced = debounce(async (spendValue: number, params: any) => {
  const {
    setSpendAmount,
    setValue,
    selectedTokenFrom,
    walletsTo,
    selectedTokenTo,
    walletsFrom,
    selectedNetworkTo,
    selectedNetworkFrom,
    setParamsLoading,
    setBridgeFeeInUsd,
    setSwapParams,
  } = params
  if (!spendValue) {
    setSpendAmount(0)
    setValue('receive', '')
    return
  }

  if (!selectedTokenFrom || !walletsTo || !selectedTokenTo || !walletsFrom) {
    return
  }
  try {
    setParamsLoading(true)
    setSwapParams(null)
    const { data } = await getSwapParams({
      token_in: selectedTokenFrom.tokenAddress,
      amount_in: convertScientificNotationNumber(spendValue),
      wallet_in: walletsFrom.address,
      token_out: selectedTokenTo.tokenAddress,
      wallet_out: walletsTo.address,
      destination_chain_id: `${selectedNetworkTo.indexerChainId}`,
      source_blockchain: selectedNetworkFrom.indexerChainId,
    })

    const receiveAmount = setDecimals(
      data.data.estimation.dstChainTokenOut.recommendedAmount,
      data.data.estimation.dstChainTokenOut.decimals,
    )
    setSpendAmount(spendValue)
    setValue('receive', receiveAmount ? convertScientificNotationNumber(receiveAmount) : '')

    setBridgeFeeInUsd(
      data.data.estimation.srcChainTokenIn.approximateUsdValue -
        data.data.estimation.dstChainTokenOut.approximateUsdValue,
    )
    setSwapParams(data.data)
  } catch (err) {
    extractErrorDescription(err)
    setValue('receive', '')
  } finally {
    setParamsLoading(false)
  }
}, 1000)

const Bridge = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [selectedTokenFrom, setSelectedTokenFrom] = useState<TBridgeTokenItem | null>(null)
  const [selectedTokenTo, setSelectedTokenTo] = useState<TBridgeTokenItem | null>(null)
  const [spendAmount, setSpendAmount] = useState(0)
  const [bridgeFeeInUsd, setBridgeFeeInUsd] = useState(0)
  const [paramsLoading, setParamsLoading] = useState(false)
  const [swapParams, setSwapParams] = useState<TGetSwapInfoResponse | null>(null)

  const { setModalProps } = useModal()
  const navigate = useCustomNavigate()
  const location = useLocation()
  const spendInput = useRef(null)

  const previousState = location.state as TBridgeConfirmState | undefined

  const defaultValues = useMemo(() => {
    if (previousState) {
      setSpendAmount(previousState.amountSend.amount)
      setBridgeFeeInUsd(previousState.fees.bridgeFee.usdValue)
      setSwapParams(previousState.swapParams)
      setTimeout(() => {
        ;(spendInput.current as any)?.setInputMode(EBridgeInputMode.AMOUNT)
      }, 10)
      return {
        networkFrom: chainsConfig.find(
          (item) => item.indexerChainId === previousState.sendAsset.networkId,
        )!,
        networkTo: chainsConfig.find(
          (item) => item.indexerChainId === previousState.receiveAsset.networkId,
        )!,
        walletsFrom: null as null | TBalancesItem,
        walletsTo: null as null | TBalancesItem,
        spend: convertScientificNotationNumber(previousState.amountSend.amount),
        receive: convertScientificNotationNumber(previousState.amountReceive.amount),
      }
    }
    return {
      networkFrom: currentChain,
      networkTo: chainsConfig.find((item) => item.indexerChainId !== currentChain.indexerChainId)!,
      walletsFrom: null as null | TBalancesItem,
      walletsTo: null as null | TBalancesItem,
      spend: '',
      receive: '',
    }
  }, [])

  const validationSchema = yup.object({
    spend: stringOfNumbersValidation.required(),
    receive: stringOfNumbersValidation.required(),
    walletsFrom: yup.object().required(),
    walletsTo: yup.object().required(),
    networkFrom: yup.object().required(),
    networkTo: yup.object().required(),
  })

  const formData = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema as any),
    mode: 'all',
  })

  const { handleSubmit, watch, setValue, getValues } = formData

  const selectedNetworkFrom = watch('networkFrom') as TChainConfig
  const selectedNetworkTo = watch('networkTo') as TChainConfig
  const walletsFrom = watch('walletsFrom')
  const walletsTo = watch('walletsTo')
  const spend = watch('spend')
  const receive = watch('receive')

  const bridgeFeeInTokens = bridgeFeeInUsd / (selectedTokenFrom?.cost || 0)

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!swapParams) {
      return
    }

    const payload: TBridgeConfirmState = {
      sendAsset: {
        name: selectedTokenFrom?.networkLabel || '',
        symbol: selectedTokenFrom?.tokenSymbol || '',
        address: selectedTokenFrom?.tokenAddress || '',
        networkId: data.networkFrom?.indexerChainId || 0,
      },
      receiveAsset: {
        name: selectedTokenTo?.networkLabel || '',
        symbol: selectedTokenTo?.tokenSymbol || '',
        address: selectedTokenTo?.tokenAddress || '',
        networkId: data.networkTo?.indexerChainId || 0,
      },
      walletFrom: {
        name: data.walletsFrom?.name || '',
        address: data.walletsFrom?.address || '',
      },
      walletTo: {
        name: data.walletsTo?.name || '',
        address: data.walletsTo?.address || '',
      },
      amountSend: {
        amount: spendAmount,
        symbol: selectedTokenFrom?.tokenSymbol || '',
        usdValue: spendAmount * (selectedTokenFrom?.cost || 0),
      },
      amountReceive: {
        amount: +data.receive || 0,
        symbol: selectedTokenTo?.tokenSymbol || '',
        usdValue: (+data.receive || 0) * (selectedTokenTo?.cost || 0),
      },
      fees: {
        bridgeFee: {
          amount: bridgeFeeInTokens,
          symbol: selectedTokenFrom?.tokenSymbol || '',
          usdValue: bridgeFeeInUsd,
        },
      },
      swapParams,
    }

    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.BRIDGE_CONFIRM}`,
      state: payload,
    })
  }

  useEffect(() => {
    setModalProps({ title: 'Bridge', subTitle: 'Move your tokens fast, easy, and safe.' })
  }, [])

  const resetAmountFields = () => {
    setValue('spend', '')
    setValue('receive', '')
    setValue('walletsFrom', null)
    setValue('walletsTo', null)
    setSpendAmount(0)
    setSwapParams(null)
    setBridgeFeeInUsd(0)
  }

  const calculateReceive = (value: number, newValues = {}) => {
    calculateReceiveDebounced(value, {
      setSpendAmount,
      setValue,
      selectedTokenFrom,
      walletsTo,
      selectedTokenTo,
      walletsFrom,
      selectedNetworkTo,
      selectedNetworkFrom,
      setParamsLoading,
      setBridgeFeeInUsd,
      setSwapParams,
      ...newValues,
    })
  }

  // NOTE: The MVP version doesn't allow users to enter the desired receive amount
  // const calculateSpend = (receiveValue: number) => {
  //   ;(spendInput.current as any)?.setInputMode(EBridgeInputMode.AMOUNT)
  //   const newSpendAmount =
  //     ((selectedTokenTo?.cost || 0) / (selectedTokenFrom?.cost || 0)) * receiveValue
  //   const newSpendAmountWithFee = newSpendAmount + newSpendAmount * BRIDGE_FEE_PERCENT
  //   setSpendAmount(newSpendAmountWithFee)
  //   setValue(
  //     'spend',
  //     convertScientificNotationNumber(
  //       newSpendAmountWithFee ? convertScientificNotationNumber(newSpendAmountWithFee) : '',
  //     ),
  //   )
  // }

  // const availableTokensToReceive =
  //   +(walletsFrom?.balance.formatted || 0) *
  //   ((selectedTokenFrom?.cost || 0) / (selectedTokenTo?.cost || 0))

  const isSpendValid = useMemo(() => {
    const value = +spend
    if (!walletsFrom) {
      return true
    }
    if ((spendInput.current as any)?.getInputMode() === EBridgeInputMode.PERCENTAGE) {
      return +value <= 100
    }
    return +value <= +walletsFrom.balance.formatted
  }, [spend, walletsFrom])

  const isSubmitValid = useMemo(
    () =>
      !!swapParams &&
      !!walletsFrom &&
      !!walletsTo &&
      !!spendAmount &&
      spendAmount <= +walletsFrom.balance.formatted,
    [swapParams, spendAmount, walletsFrom, walletsTo],
  )

  return (
    <Grid gap={2} display="grid">
      <FormProvider {...formData}>
        <BridgeTokenSelectWithWallets
          title="Spend token"
          walletsLabel="Wallet from"
          setSelectedToken={setSelectedTokenFrom}
          selectedNetwork={selectedNetworkFrom}
          walletsFieldName="walletsFrom"
          chainFieldName="networkFrom"
          defaultWalletAddress={previousState?.walletFrom.address}
          disabled={paramsLoading}
          onChainChange={(oldChain, newChain) => {
            resetAmountFields()
            if (newChain.indexerChainId === selectedNetworkTo.indexerChainId) {
              setValue('networkTo', oldChain)
            }
          }}
          onWalletsChange={(newWalletFrom) => {
            if (!newWalletFrom) {
              return
            }
            calculateReceive(
              (spendInput.current as any)?.getInputMode() === EBridgeInputMode.PERCENTAGE
                ? (+newWalletFrom.balance.formatted * +getValues('spend')) / 100
                : +getValues('spend'),
              {
                walletsFrom: newWalletFrom,
              },
            )
          }}
        />

        <BridgeAmountInput
          name="spend"
          label="Spend amount"
          placeholder="Enter amount to spend"
          availableTokens={+(walletsFrom?.balance.formatted || 0)}
          availableTokensInUsd={
            +(walletsFrom?.balance.formatted || 0) * (selectedTokenFrom?.cost || 0)
          }
          tokenSymbol={selectedNetworkFrom.chainSymbol}
          tokenIcon={selectedNetworkFrom.iconName}
          withPercentageMode
          withAvailableTokens
          ref={spendInput}
          isValid={isSpendValid}
          onChange={(value) => {
            calculateReceive(+value)
          }}
          disabled={paramsLoading}
          valueInUsd={spendAmount * (selectedTokenFrom?.cost || 0)}
        />

        <Grid display="flex" alignItems="center">
          <div className={cn(styles.divider, styles.dividerSmall)} />
          <div className={styles.feeArrow}>
            <Icon name={IconName.ARROW_DOWN_GREY} />
          </div>
          <Typography variant="body1" className={styles.feeText}>
            Bridge fee: {formatNumber(bridgeFeeInTokens).formatted}{' '}
            {selectedNetworkFrom.chainSymbol} (~$
            {formatNumber(bridgeFeeInUsd, 2).formatted})
          </Typography>
          {/* This section is used to display the fee in percentage
          <Typography variant="body1" className={styles.feeText}>
            Bridge fee: {formatNumber(bridgeFeeInUsd, 2).formatted}%
          </Typography> */}
          <div className={styles.divider} />
        </Grid>

        <BridgeTokenSelectWithWallets
          title="Receive token"
          walletsLabel="Wallet to"
          setSelectedToken={setSelectedTokenTo}
          selectedNetwork={selectedNetworkTo}
          walletsFieldName="walletsTo"
          chainFieldName="networkTo"
          defaultWalletAddress={previousState?.walletTo.address}
          disabled={paramsLoading}
          onChainChange={(oldChain, newChain) => {
            resetAmountFields()
            if (newChain.indexerChainId === selectedNetworkFrom.indexerChainId) {
              setValue('networkFrom', oldChain)
            }
          }}
          onWalletsChange={(newWalletTo) => {
            if (!walletsFrom || !newWalletTo || newWalletTo.address === walletsTo?.address) {
              return
            }
            calculateReceive(
              (spendInput.current as any)?.getInputMode() === EBridgeInputMode.PERCENTAGE
                ? (+walletsFrom.balance.formatted * +getValues('spend')) / 100
                : +getValues('spend'),
              {
                walletsTo: newWalletTo,
              },
            )
          }}
        />

        <BridgeAmountInput
          name="receive"
          label="Receive amount"
          placeholder="Enter amount to receive"
          tokenSymbol={selectedNetworkTo.chainSymbol}
          tokenIcon={selectedNetworkTo.iconName}
          withoutOptions
          disabled
          valueInUsd={+receive * (selectedTokenTo?.cost || 0)}
          // TODO: Uncomment when the spend amount can be calculated from the receive on the backend
          // availableTokens={availableTokensToReceive}
          // availableTokensInUsd={availableTokensToReceive * (selectedTokenTo?.cost || 0)}
          // onChange={(value) => {
          //   calculateSpend(+value)
          // }}
        />

        <Button onClick={handleSubmit(onSubmit as any)} disabled={!isSubmitValid}>
          Continue
        </Button>
      </FormProvider>
    </Grid>
  )
}

export { Bridge }
