import { FC } from 'react'

import MUITypography, { TypographyProps as MUITypographyProps } from '@mui/material/Typography'
import cn from 'classnames'

import styles from './styles.module.scss'

type TypographyProps = MUITypographyProps & {
  variantWeight?: 'inherit' | 'regular' | 'medium' | 'semibold' | 'bold'
  isEmphasize?: boolean
  resetLineHeight?: boolean
  unsetLineHeight?: boolean
  isUpperCase?: boolean
  textColor?:
    | 'light-grey'
    | 'orange'
    | 'color-grey-6'
    | 'white'
    | 'color-grey-9'
    | 'color-grey-4'
    | 'grey'
    | 'green'
    | 'color-grey-3'
    | 'color-grey-2'
    | 'gold'
    | 'color-white-3'
    | 'color-lightgrey-3'
    | 'black'
    | 'red'
}

const Typography: FC<TypographyProps> = ({
  children,
  isEmphasize,
  variantWeight = 'inherit',
  textAlign = '',
  textColor = '',
  resetLineHeight = false,
  unsetLineHeight = false,
  isUpperCase = false,
  className,
  fontWeight,
  ...props
}) => {
  return (
    <MUITypography
      classes={styles}
      className={cn(
        [styles[textColor]],
        {
          [styles.emphasize]: isEmphasize,
          [styles[variantWeight]]: variantWeight,
          [styles[`text-${textAlign}`]]: textAlign,
          [styles.resetLineHeight]: resetLineHeight,
          [styles.unsetLineHeight]: unsetLineHeight,
          [styles.uppercase]: isUpperCase,
        },
        className,
      )}
      style={!!fontWeight ? { fontWeight: +fontWeight } : {}}
      {...props}
    >
      {children}
    </MUITypography>
  )
}

export { Typography }
export type { TypographyProps }
