import { FC, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { makeManualBuy } from '@/api/orders'
import { BuyAmountGroup } from '@/components/buy-amount-group'
import { CustomToast } from '@/components/custom-toast'
import { MAX_AMOUNT_LENGTH } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/constants'
import styles from '@/components/dashboard-component-lite/libs/buy-sell-block/styles.module.scss'
import useCheckUser from '@/hooks/useCheckUser'
import { Accordion, Button, Icon, Input, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, IconName, TransactionResponseType } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { handleError } from '@/libs/helper/handleError'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { TBuyTransaction } from '@/libs/types/buy-transaction'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppSelector } from '@/store'

type TProperty = {
  onExpand?: (value: boolean) => void
}

const BuyTab: FC<TProperty> = ({ onExpand }) => {
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const quickBuySettings = useAppSelector((state) => state.user.quickBuySettings)
  const [isLoading, setIsLoading] = useState(false)

  const checkUserAndExecute = useCheckUser()

  const formRef = useRef<HTMLFormElement>(null)

  const defaultValues = {
    amount: quickBuySettings.isInitialized ? quickBuySettings.settings.spend : '',
    privateTransaction: !currentChain.features?.noPrivateTx,
    slippage: currentChain.defaultValues.slippage,
    buyPriority: defaultUserPriorities.buy_priority ?? '',
  }

  const schema = yup.object({
    amount: stringOfNumbersValidation
      .required()
      .test((value) => +value <= +(mainWallet?.balanceFormatted || 0)),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!mainWallet) return

    try {
      setIsLoading(true)

      const coeff =
        currentChain.nativeTokenPriceInUsd && currentToken?.token.price
          ? currentChain.nativeTokenPriceInUsd / +currentToken?.token.price
          : 0
      const receive = convertScientificNotationNumber(+data.amount * coeff, MAX_TRX_DECIMALS)

      const payload = createManualBuyPayload({
        data: {
          advancedBuy: {
            approvePriority: '',
            buyPriority: data.buyPriority,
            maxTxOrFail: false,
            minPercentTokenOrFail: '',
          },
          shields: {
            buy_tax: '',
            maximum_liquidity: '',
            maximum_market_cap: '',
            minimum_liquidity: '',
            sell_tax: '',
          },
          ordinaryBuy: {
            degenChadMode: false,
            privateTransaction: data.privateTransaction,
            receive: receive,
            selectedWallets: [mainWallet.address],
            slippage: data.slippage,
            spend: data.amount,
          },
        },
        wallets: [
          {
            name: mainWallet.name,
            public_key: mainWallet.address,
          },
        ],
      }) as TBuyTransaction

      if (!payload) {
        CustomToast('error', 'Something went wrong')
        return
      }
      const response = await makeManualBuy(payload)
      processTransactionResponse(response.data, TransactionResponseType.BUY)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Accordion
        className={styles.accordion}
        titleClassName={styles.accordionTitle}
        title="Advanced Settings"
        withDivider
        mainColorArrow
        onChange={onExpand}
      >
        <Grid container rowGap={2}>
          {!currentChain.features?.noPrivateTx && (
            <Controller
              name="privateTransaction"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <SwitchInline
                  label="Anti-Mev"
                  tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                  {...field}
                />
              )}
            />
          )}

          <Controller
            name="slippage"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PercentsInput
                label="Slippage"
                onOptionSelect={(value) => field.onChange(value)}
                tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                placeholder="X"
                error={!!errors.slippage?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="buyPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                {...field}
                className={styles.input}
                label={getChainSensitiveLabel('buyPriority')}
                placeholder="Enter gwei amount"
                isNumeric
                tooltipInfo={`An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ${currentChain.chainSymbol}.`}
                endAdornment={
                  <EndAdornment label="GWEI" icon={(IconName as any)[currentChain.iconName]} />
                }
                error={!!errors.buyPriority?.message}
                onChange={(e: any) => {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )

                  field.onChange(newValue)
                }}
              />
            )}
          />
        </Grid>
      </Accordion>

      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => {
          return (
            <BuyAmountGroup
              solid
              exclusive
              className={styles.radioGroup}
              groupClassName={styles.radioGroupContent}
              value={value}
              onChange={(_, value) => {
                if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                const newValue = convertScientificNotationNumber(value, MAX_TRX_DECIMALS, true)
                onChange(newValue)
              }}
              {...field}
            />
          )
        }}
      />

      <Link
        to={`${AppRoute.DASHBOARD}/${AppMode.LITE}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`}
        className={styles.link}
      >
        <Typography variant="body2">
          {getChainSensitiveLabel('setupDefaultPriorities')} <Icon name={IconName.SETTING_2} />
        </Typography>
      </Link>

      <div className={styles.divider}>
        <Typography variant="body2" className={styles.text}>
          OR
        </Typography>
      </div>
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => {
          return (
            <Input
              label="Amount"
              placeholder="Enter amount to buy"
              isNumeric
              className={styles.input}
              maxLength={MAX_AMOUNT_LENGTH}
              isHideInputCaption
              endAdornment={
                <Grid display="flex" alignItems="center" columnGap={2}>
                  <Typography fontWeight={400}>
                    ($
                    {
                      formatNumber(+field.value * (currentChain.nativeTokenPriceInUsd || 0), 2)
                        .formatted
                    }
                    )
                  </Typography>
                  <EndAdornment
                    label={currentChain.chainSymbol}
                    icon={(IconName as any)[currentChain.iconName]}
                  />
                </Grid>
              }
              onChange={(e) => {
                if (!currentToken || !currentChain.nativeTokenPriceInUsd) return

                if (!e.target.value) {
                  onChange(e.target.value)
                  return
                } else {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  onChange(newValue)
                }
              }}
              error={!!errors.amount?.message}
              {...field}
            />
          )
        }}
      />
      <div className={styles.available}>
        <Typography variant="body2" className={styles.param}>
          Available:
        </Typography>
        <Typography variant="body2" className={styles.value}>
          {mainWallet?.balanceFormatted
            ? (+mainWallet.balanceFormatted || 0).toLocaleString('en-US', {
                maximumFractionDigits: 5,
              })
            : 0}{' '}
          {currentChain.chainSymbol}
        </Typography>
      </div>

      <Button
        type="button"
        isLoading={isLoading}
        checkOnAccountLock
        onClick={() => {
          checkUserAndExecute(() => {
            formRef.current?.requestSubmit()
          })
        }}
      >
        Buy
      </Button>
    </form>
  )
}

export { BuyTab }
