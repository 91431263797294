import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Grid } from '@mui/material'

import { swapTokens } from '@/api/bridge'
import { CustomToast } from '@/components/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { Button } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { useModal } from '@/pages/modal-page/modal-page'

import { BridgeInfoItem } from './libs/components/bridge-info-item'
import { TBridgeConfirmState } from './libs/types'

const BridgeConfirm = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { setModalProps } = useModal()
  const location = useLocation()
  const navigate = useCustomNavigate()

  const data = location.state as TBridgeConfirmState

  const submit = async () => {
    if (!data) return
    try {
      setIsLoading(true)

      await swapTokens({
        ...data.swapParams.tx,
        wallet: {
          name: data.walletFrom.name,
          public_key: data.walletFrom.address,
        },
        source_blockchain: data.sendAsset.networkId,
      })

      CustomToast('success', 'Your transfer was successfully completed')
      navigate({ isDashboard: true })
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setModalProps({
      title: 'Confirm transfer',
      withBackButton: true,
      customBackButtonHandler: () => {
        navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.BRIDGE}`, state: data })
      },
    })
  }, [])

  if (!data) {
    return <></>
  }

  return (
    <div>
      <BridgeInfoItem
        label="Asset send"
        value={data.sendAsset.name}
        subValue={data.sendAsset.symbol}
        subValueAsValue
      />
      <BridgeInfoItem
        label="Asset receive"
        value={data.receiveAsset.name}
        subValue={data.receiveAsset.symbol}
        subValueAsValue
      />
      <BridgeInfoItem
        label="From"
        value={data.walletFrom.name}
        subValue={hideWalletAddress(data.walletFrom.address)}
      />
      <BridgeInfoItem
        label="To"
        value={data.walletTo.name}
        subValue={hideWalletAddress(data.walletTo.address)}
      />
      <BridgeInfoItem
        label="Amount send"
        value={
          <>
            -{formatNumber(data.amountSend.amount).formatted} {data.amountSend.symbol}
          </>
        }
        subValue={<>~${formatNumber(data.amountSend.usdValue).formatted}</>}
      />
      <BridgeInfoItem
        label="Amount receive"
        value={
          <>
            +{formatNumber(data.amountReceive.amount).formatted} {data.amountReceive.symbol}
          </>
        }
        subValue={<>~${formatNumber(data.amountReceive.usdValue).formatted}</>}
      />
      <BridgeInfoItem
        label="Bridge fee"
        value={
          <>
            {formatNumber(data.fees.bridgeFee.amount).formatted} {data.fees.bridgeFee.symbol}
          </>
        }
        subValue={<>~${formatNumber(data.fees.bridgeFee.usdValue, 2).formatted}</>}
      />
      {/* <BridgeInfoItem
        label="Bridge fee"
        value={<>{formatNumber(data.fees.bridgeFee.percentage).formatted} %</>}
        small
      /> */}

      <Grid marginTop={3}>
        <Button isLoading={isLoading} onClick={submit}>
          Transfer
        </Button>
      </Grid>
    </div>
  )
}

export { BridgeConfirm }
