import {
  DEFAULT_TOKEN,
  DEFAULT_TOKEN_BASE,
  DEFAULT_TOKEN_BSC,
} from '@/libs/configs/default-token.config.ts'
import { ChainName } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'

const chainsConfig: TChainConfig[] = [
  {
    id: 1,
    chainName: ChainName.ETHEREUM,
    label: 'Ethereum Chain',
    networkLabel: 'Ethereum',
    description: 'ETH',
    defaultToken: DEFAULT_TOKEN,
    chainSymbol: 'ETH',
    iconName: 'ETHEREUM',
    scanLogo: 'ETHERSCAN',
    nativeTokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    indexerChainId: 1,
    chainId: 1,
    chainDexes: [
      {
        id: 0,
        label: 'Uniswap V2',
      },
      {
        id: 1,
        label: 'Uniswap V3',
      },
      {
        id: 10,
        label: 'SushiSwap V2',
      },
      {
        id: 11,
        label: 'SushiSwap V3',
      },
      {
        id: 20,
        label: 'PancakeSwap V2',
      },
      {
        id: 21,
        label: 'PancakeSwap V3',
      },
      {
        id: 30,
        label: 'ShibaSwap V2',
      },
    ],
    explorer: 'https://etherscan.io',
    features: {
      noAntiDuplicate: true,
      buyPriority: true,
    },
    defaultValues: {
      slippage: 50,
      txType: 1,
    },
  },
  {
    id: 2,
    chainName: ChainName.BNB,
    label: 'Binance Smart Chain',
    networkLabel: 'BNB',
    description: 'BSC',
    defaultToken: DEFAULT_TOKEN_BSC,
    chainSymbol: 'BNB',
    scanLogo: 'BSCSCAN',
    iconName: 'BNB_V2',
    nativeTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    indexerChainId: 2,
    chainId: 56,
    chainDexes: [
      {
        id: 0,
        label: 'PancakeSwap V2',
      },
      {
        id: 1,
        label: 'PancakeSwap V3',
      },
      {
        id: 11,
        label: 'Uniswap V3',
      },
      {
        id: 20,
        label: 'ApeSwap V2',
      },
      {
        id: 30,
        label: 'BakerySwap V2',
      },
    ],
    explorer: 'https://bscscan.com',
    features: {
      noPriorityGas: true,
      noPrivateTx: true,
      buyPriority: true,
      approvePriority: false,
      noAntiDuplicate: true,
    },
    defaultValues: {
      slippage: 25,
      txType: 0,
    },
  },
  {
    id: 5,
    chainName: ChainName.BASE,
    label: 'BASE',
    networkLabel: 'BASE',
    description: 'BASE',
    defaultToken: DEFAULT_TOKEN_BASE,
    chainSymbol: 'ETH',
    scanLogo: 'ETHERSCAN',
    iconName: 'BASE_CHAIN',
    nativeTokenAddress: '0x4200000000000000000000000000000000000006',
    indexerChainId: 5,
    chainId: 8453,
    chainDexes: [
      {
        id: 0,
        label: 'BaseSwap V2',
      },
      {
        id: 1,
        label: 'BaseSwap V3',
      },
      {
        id: 10,
        label: 'SushiSwap V2',
      },
      {
        id: 11,
        label: 'SushiSwap V3',
      },
      {
        id: 20,
        label: 'Uniswap V2',
      },
      {
        id: 21,
        label: 'Uniswap V3',
      },
    ],
    explorer: 'https://basescan.org',
    defaultValues: {
      slippage: 25,
      txType: 1,
    },
    features: {
      noPrivateTx: true,
      buyPriority: true,
      approvePriority: false,
      noAntiDuplicate: true,
    },
  },

  // {
  //   id: 42161,
  //   chainName: ChainName.ARBITRUM,
  //   label: 'Arbitrum',
  //   description: 'ARB',
  //   iconName: 'ARBITRUM',
  //   nativeTokenAddress: '0x912CE59144191C1204E64559FE8253a0e49E6548',
  //   indexerChainId: 3,
  //   chainDexes: [
  //     {
  //       id: 1,
  //       label: 'Uniswap V3',
  //     },
  //     {
  //       id: 10,
  //       label: 'SushiSwap V2',
  //     },
  //     {
  //       id: 11,
  //       label: 'SushiSwap V3',
  //     },
  //     {
  //       id: 20,
  //       label: 'Camelot V2',
  //     },
  //     {
  //       id: 21,
  //       label: 'Camelot V3',
  //     },
  //     {
  //       id: 30,
  //       label: 'ArbSwap V2',
  //     },
  //   ],
  // },
  // @TODO change IDs to base chain IDs (these were copied from ethereum)
  // @TODO change IDs to avax chain IDs (these were copied from ethereum)
  // {
  //   id: 1,
  //   chainName: ChainName.AVAX,
  //   label: 'AVAX',
  //   description: 'AVAX',
  //   iconName: 'AVAX_CHAIN',
  //   nativeTokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  //   indexerChainId: 0,
  //   chainDexes: [
  //     {
  //       id: 0,
  //       label: 'Uniswap V2',
  //     },
  //     {
  //       id: 1,
  //       label: 'Uniswap V3',
  //     },
  //     {
  //       id: 10,
  //       label: 'SushiSwap V2',
  //     },
  //     {
  //       id: 11,
  //       label: 'SushiSwap V3',
  //     },
  //     {
  //       id: 20,
  //       label: 'PancakeSwap V2',
  //     },
  //     {
  //       id: 21,
  //       label: 'PancakeSwap V3',
  //     },
  //     {
  //       id: 30,
  //       label: 'ShibaSwap V2',
  //     },
  //   ],
  // },
]

export { chainsConfig }
